






import { Component, Vue } from 'vue-property-decorator';
import AdminVersionTable from '../../molecules/tables/AdminVersionTable.vue';

@Component({
  name: 'AdminUserTab',
  components: { AdminVersionTable },
})
export default class AdminVersionTab extends Vue {}
