











































import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { Loader } from '@/ui/util/decorators/loader';
import { USER_ROLE } from '@/core/user/enums/UserRole';

@Component({
  name: 'admin-version-table',
  components: {},
})
export default class AdminVersionTable extends Vue {
  mounted() {
    this.$store.dispatch('adminStore/getVersion');
  }

  get buildVersion() {
    return this.$store.state.adminStore.buildVersion;
  }

  get buildTimestamp() {
    return this.$store.state.adminStore.buildTimestamp;
  }

  get buildStage() {
    return this.$store.state.adminStore.buildStage;
  }

  get environmentName() {
    return this.$store.state.adminStore.environmentName;
  }

  get currentUser() {
    return this.$store.state.userStore.currentUser;
  }
  get frontEndBuildVersion() {
    return process.env.VUE_APP_VERSION;
  }
  get showVersion() {
    return this.currentUser?.role === USER_ROLE.SUPER_ADMIN && this.buildVersion;
  }

  @Loader
  async refreshTable() {
    await this.$store.dispatch('adminStore/getVersion');
  }
}
